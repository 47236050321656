import React from "react"
import { graphql, Link } from "gatsby"

import Header from "../components/Layout/header"
import Footer from "../components/Layout/footer"
import ServiceCard from "../components/CardService"
import Steps from "../components/steps"
import CallMeBack from "../components/callback"
import SEO from "../components/Global/MetaHead"

const IndexPage = ({ data: { licenses, transport, ogImage } }) => {
  return (
    <>
      <SEO
        title="Центр Державних Ліцензії України"
        description="Єдине вікно державних ліцензій, отримайте ліцензії поштою за 10 днів, вся Україна. Оплата послуг лише при отриманні документів!"
        image={`https:${ogImage.fixed.src}`}
        url="/"
        lang="uk"
      />
      <Header />

      <section className="licensies container mb-5">
        <div className="py-5 title2 text-uppercase">Оберіть ліцензію</div>
        <div className="card-columns">
          {licenses.nodes.map(license => (
            <ServiceCard
              key={license.id}
              serviceTitle={license.title}
              servicePrice={license.price}
              serviceValid={license.validity}
              serviceFrom={license.termFrom}
              serviceUpTo={license.termUpTo}
              serviceTax={license.taxAmount}
              serviceLink={`/${license.seo.slug}/`}
              serviceFluidImage={license.seo.metaImage.fluid}
              serviceId={license.serviceId}
            />
          ))}
        </div>
      </section>

      <section className="container mb-5">
        <div className="py-5 title2 text-uppercase">
          Послуги для перевізників
        </div>
        <div className="card-columns">
          {transport.nodes.map(service => (
            <ServiceCard
              key={service.id}
              serviceTitle={service.title}
              servicePrice={service.price}
              serviceValid={service.validity}
              serviceFrom={service.termFrom}
              serviceUpTo={service.termUpTo}
              serviceTax={service.taxAmount}
              serviceLink={`/${service.seo.slug}/`}
              serviceFluidImage={service.seo.metaImage.fluid}
            />
          ))}
        </div>
      </section>

      <section className="py-5 zakon">
        <div className="container">
          <h1 className="pb-5 text-center">Центр Ліцензій України</h1>
          <h2 className="pb-3">Закон України про ліцензування</h2>
          <p>
            Повний перелік видів господарської діяльності, що підлягають
            ліцензуванню складає 33 пункти та опублікований на офіційному{` `}
            <a
              href="https://zakon.rada.gov.ua/laws/show/222-19"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="text-white"
            >
              сайті Верховної Ради України
            </a>
            , поточна редакція від 19.08.2022.
          </p>
          <p>На період воєнного стану, для усіх видів ліцензій:</p>
          <ul>
            <li>вдвічі скорочено обсяг необхідних документів;</li>
            <li>скасовано підтвердження трирічного досвіду роботи;</li>
            <li>скасовано документи про матеріально-технічну базу;</li>
            <li>термін розгляду заявки скорочено з 10 до 1–3 днів.</li>
          </ul>
          <p>Спрощення процедури стосується ліцензій на:</p>
          <ul>
            <li>
              <Link to="/licenziya-avtobus/" className="white-link">
                внутрішні та міжнародні перевезення пасажирів автобусами
              </Link>
              ;
            </li>
            <li>
              <Link to="/licenziya-perevozka-adr/" className="white-link">
                внутрішні та міжнародні перевезення небезпечних вантажів та
                небезпечних відходів вантажними автомобілями
              </Link>
              ;
            </li>
            <li>
              <Link
                to="/licenziya-mizhnarodni-perevezennya/"
                className="white-link"
              >
                міжнародні перевезення вантажів вантажними автомобілями (крім
                перевезення небезпечних вантажів та небезпечних відходів)
              </Link>
              .
            </li>
          </ul>
          <h2 className="py-3">Хто видає в Україні транспортні ліцензії?</h2>
          <p>
            Єдиний орган в Україні, який видає транспортні ліцензії є Державна
            служба України з безпеки на транспорті (Укртрансбезпека).
          </p>
          <h2 className="py-3">
            Який штраф при роботі без транспортної ліцензії?
          </h2>
          <p>
            Наявність ліцензії в Україні перевіряють працівники Укртрансбезпеки,
            у разі її відсутності складається адміністративний протокол по 164
            ст КУпАП (штраф від 17000 до 34000 грн).
          </p>
          <h2>Хто ліцензує медичну практику?</h2>
          <p>
            Ліцензії на медичну практику видає Міністерство Охорони Здоров'я
            України (МОЗ), яке знаходиться за адресою — Київ, вул. Грушевського,
            7.
          </p>
        </div>
      </section>
      <Steps />
      <CallMeBack />
      <Footer slug="/" />
    </>
  )
}

export const query = graphql`
  query {
    ogImage: contentfulAsset(
      file: { fileName: { eq: "center-licenziy-opengraph-min.png" } }
    ) {
      id
      title
      fixed(width: 620, height: 460) {
        src
      }
    }
    services: allContentfulService(
      filter: { node_locale: { eq: "uk" } }
      sort: { fields: serviceId, order: ASC }
    ) {
      totalCount
      nodes {
        serviceId
        title
        id
        price
        ltdPrice
        termFrom
        termUpTo
        validity
        taxAmount
        type {
          type
        }
        seo {
          slug
          metaImage {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    licenses: allContentfulService(
      filter: { node_locale: { eq: "uk" }, type: { type: { eq: "Ліцензія" } } }
      sort: { fields: serviceId, order: ASC }
    ) {
      totalCount
      nodes {
        serviceId
        title
        id
        price
        ltdPrice
        termFrom
        termUpTo
        validity
        taxAmount
        type {
          type
        }
        seo {
          slug
          metaImage {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    transport: allContentfulService(
      filter: { node_locale: { eq: "uk" }, type: { type: { eq: "Послуга" } } }
      sort: { fields: serviceId, order: ASC }
    ) {
      totalCount
      nodes {
        serviceId
        title
        id
        price
        ltdPrice
        termFrom
        termUpTo
        validity
        taxAmount
        type {
          type
        }
        seo {
          slug
          metaImage {
            fluid(maxWidth: 350) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default IndexPage
